// PasswordReset.js
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../firebaseConfig'; // Assurez-vous que le chemin d'accès est correct
import { PacmanLoader } from 'react-spinners'; // Assurez-vous que vous avez installé react-spinners
import '../styles/PasswordReset.css';

const PasswordReset = () => {
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const resetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    setIsLoading(true);
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      alert('Password reset successfully. You can now login with the new password.');
      navigate('/login');
    } catch (error) {
      console.error(error);
      setError('Failed to reset password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="spinner-container">
          <PacmanLoader color="#2563eb" />
        </div>
      ) : (
        <div>
          <header className="reset-header">
            <h1 onClick={() => navigate('/')} style={{cursor: 'pointer'}}>CargoPero</h1>
          </header>
             <div className="reset-container">
              <div className="reset-form">
                {error && <p className="error-message">{error}</p>}
                <input 
                  type="password" 
                  value={newPassword} 
                  onChange={(e) => setNewPassword(e.target.value)} 
                  className="reset-input" 
                  placeholder="New Password"
                />
                <input 
                  type="password" 
                  value={confirmPassword} 
                  onChange={(e) => setConfirmPassword(e.target.value)} 
                  className="reset-input" 
                  placeholder="Confirm New Password"
                />
                <button onClick={resetPassword} className="reset-button">Reset Password</button>
              </div>
            </div>
        </div>
      )}
    </div>
  );
};

export default PasswordReset;
