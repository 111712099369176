// src/components/rewardsList.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RewardsCard from './rewardsCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import '../styles/ProductsList.css'; // Utiliser le même CSS pour conserver le style
import MainNavigation from './MainNavigation';

const RewardsList = () => {
    const navigate = useNavigate();
    const [rewardsData, setRewardsData] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL

    useEffect(() => {
        const userHash = localStorage.getItem('user_hash'); // Récupérer le user_hash du localStorage
        console.log('User Hash:', userHash);
        
        fetch(`${apiUrl}/api/rewards/${userHash}`)
            .then(response => response.json())
            .then(data => {
                console.log('Rewards data:', data); // Log pour suivre les données récupérées
                setRewardsData(data);
            })
            
            .catch(error => console.error('Erreur lors de la récupération des rewards:', error));
    }, []);

    return (
      <div>
        <MainNavigation />
        <div className="listContainer">
            <h2>REWARDS</h2>
            {rewardsData.length === 0 ? (
                <div className="emptyRewards">
                    <p>Looks like your reward meter’s at zero. Let’s change that! Share our events with newbies, wait for their first top up, and we’ll hook you up with a reward for every newcomer brought in by your sharing</p>
                    <button onClick={() => navigate('/events')} className="btn-primary">SHARE EVENT</button>
                </div>
            ) : (
                rewardsData.map((reward, index) => (
                    [...Array(reward.nbreAttribute)].map((e, i) => <RewardsCard key={`${index}-${i}`} {...reward} />)
                ))
            )}
        </div>
      </div>
    );
};

export default RewardsList;
