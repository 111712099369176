// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Importez votre configuration Firebase
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { PacmanLoader } from 'react-spinners';
import '../styles/Login.css'; // Assurez-vous que le chemin d'accès est correct

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false); 
  const [error, setError] = useState('');
    
  const handlePasswordReset = async () => {
    if (!email) {
      setError('Please enter your email address to reset your password.');
      return;
    }
    console.log('Attempting to send password reset email to:', email);
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent successfully.');
      alert('Password reset link sent! Check your email.');
    } catch (error) {
      console.error(error);
      setError('Failed to send password reset email. Please try again.');
    }
  };    
    

const handleLogin = async () => {
  try {
    setIsLoading(true);
    setError('');
    // Authentification avec Firebase
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const apiUrl = process.env.REACT_APP_API_URL;

    // Recharger les informations de l'utilisateur pour s'assurer qu'elles sont à jour
    await user.reload();

    // Vérifier si l'email a été vérifié
    if (user.emailVerified) {
      console.log(user);

      // Sauvegarde le token dans le localStorage
      const token = await user.getIdToken();
      localStorage.setItem('token', token);

      // Requête pour vérifier l'authenticité du token  
      fetch(`${apiUrl}/api/users/profile`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
      .then(response => response.json())
      .then(data => {
        console.log(data); // Log the response from the backend
        localStorage.setItem('user_hash', data.user_hash); // Stocker le user_hash dans le localStorage
        
        // Redirection en fonction du rôle de l'utilisateur
        switch (data.role) {
          case 'manager':
            navigate('/manager');
            break;
          case 'staff':
            navigate('/orders-list'); // Redirection pour le rôle staff
            break;
          default:
            navigate('/events'); // Cas par défaut pour les autres rôles
        }
      })
      .catch((error) => {    
        console.error('Error:', error);
      });
    } else {
      // Rediriger l'utilisateur vers la page de vérification d'email
      navigate('/verify-email');
    }
  } catch (error) {
    console.error(error.message);   
    setError("Email or password is incorrect. Please try again.");
  } finally {
    setIsLoading(false); 
  }
};

  return (
    <div>
      {isLoading ? (
        // Afficher le loader si isLoading est true
        <div className="spinner-container">
            <PacmanLoader color="#2563eb" />
        </div>
      ) : (
        // Le reste de votre composant de connexion
        <div>
          <header className="login-header">
            <h1 onClick={() => navigate('/')} style={{cursor: 'pointer'}}>CargoPero</h1>
          </header>
          <div className="login-container">
            {error && <p className="error-message">{error}</p>}
            <form className="login-form" onSubmit={e => e.preventDefault()}>
              <input 
                type="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                className="login-input" 
                placeholder="Email"
              />
              <input 
                type="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                className="login-input" 
                placeholder="Password"
              />
              <button onClick={handleLogin} className="login-button">Login</button>
              <a onClick={() => navigate('/signup')} className="login-link">
                Don't have an account? Sign up
              </a>
              <a onClick={() => navigate('/password-request')} className="login-link">Forgot Password?</a>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;

