import React from 'react';
import '../styles/OrdersCard.css'; // Assurez-vous de créer ce fichier CSS basé sur ProductsCard.css

const ReceiptCard = ({ orderDetails }) => {
  // Le useState pour userName et la logique associée ont été supprimés.
  const orderStatus = orderDetails[0].status; // Utilisation directe sans useState puisque le handleProcessClick est supprimé

  // Le useEffect associé à la récupération du nom d'utilisateur a également été supprimé.

  return (
    <div className={`orderContainer ${orderStatus === 'processing' ? 'processingOrder' : ''}`}>
      <div className="orderCard">
        <div className="orderHeader">
          <h5>ID: {orderDetails[0].order_id}</h5>
        </div>
        <div className="orderBody">
          {orderDetails.map(item => (
            <div key={item.productName} className="orderItem">
              <span>{item.productName}</span>
              <span>{item.quantity}</span>
            </div>
          ))}
        </div>
        <div className="orderFooter">
          <span>{orderStatus}</span>
          {/* Suppression du bouton. */}
        </div>
      </div>
    </div>
  );
};

export default ReceiptCard;


