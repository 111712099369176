import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import '../styles/StockAndProfit.css';
import MainNavigationManager from './MainNavigationManager';

const StockAndProfit = () => {
    const [profit, setProfit] = useState(0);
    const [stocks, setStocks] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL
    const navigate = useNavigate();

    // Charger le profit total
    useEffect(() => {
        fetch(`${apiUrl}/api/analytics/profits/latest`) // Remplacez par l'URL réelle de votre API
            .then(response => response.json())
            .then(data => setProfit(data.totalProfit))
            .catch(error => console.error('Error fetching profit:', error));
    }, []);

    // Charger l'état actuel des stocks
    useEffect(() => {
        fetch(`${apiUrl}/api/analytics/stocks/state`) // Remplacez par l'URL réelle de votre API
            .then(response => response.json())
            .then(data => setStocks(data))
            .catch(error => console.error('Error fetching stocks:', error));
    }, []);

    return (
      <div>
        <MainNavigationManager />
        <div className="stockAndProfitContainer">
          <FontAwesomeIcon icon={faChartPie} size="6x" style={{ color: "#2563eb", marginTop: "30px" }} />
          <h2>Total profit: {profit.toLocaleString('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</h2>
          <h3>Current stocks:</h3>
              <ul className="stockAndProfitList">
              {stocks.map(stock => (
                <li key={stock.ingredientName}>{stock.ingredientName}: {stock.quantityAvailable} {stock.unit}</li>
              ))}
            </ul>
        </div>
      </div>
    );
};

export default StockAndProfit;
