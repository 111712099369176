import React, { useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';

const ManagerScan = () => {
  const [scanResult, setScanResult] = useState(null);
  const [isScanning, setIsScanning] = useState(true);

  const handleScan = (data) => {
    if (data && isScanning) {
      setIsScanning(false);
      setScanResult(data.text);
      handleFetch(data.text);
    }
  };

  const handleFetch = async (userHash) => {
    const managerHash = "6f674cd9757cb29903d4d3d3b75f6c155a724c9cf793c7380d1c000589de1829";
    const amount = 10000;
    const payload = {
      userHash: userHash,
      managerHash: managerHash,
      amount: amount,
      type: "TopUp" // topup or payment
    };

    try {
        
      const API_URL = process.env.REACT_APP_API_URL || 'https://cashqr.local:3002';
      const response = await fetch(`${API_URL}/transactions/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json();
      console.log('Success:', data);
      setScanResult(null);
      setIsScanning(true);
    } catch (error) {
      console.error('Error:', error);
      setIsScanning(true);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <h1>Scan QR</h1>
      {isScanning && (
        <QrReader
          delay={300}
          onResult={handleScan}
          onError={handleError}
          constraints={{ facingMode: 'environment' }}
          style={{ width: '100%' }}
        />
      )}
      {scanResult && <p>Scanned QR Code: {scanResult}</p>}
    </div>
  );
};

export default ManagerScan;

