import React, { useState, useEffect } from 'react';
import OrdersCard from './ReceiptCard';
import MainNavigation from './MainNavigation';
import '../styles/OrdersList.css';

const ReceiptList = () => {
  console.log('Component rendered');
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL;
    const userHash = localStorage.getItem('user_hash');

    const fetchOrders = () => {
      fetch(`${API_URL}/api/orders/user/${userHash}`)
        .then(response => response.json())
        .then(data => {
          console.log('Orders fetched for user:', data);

          // Crée un tableau à partir des objets, et filtre les commandes sans order_id
          const ordersArray = Object.entries(data)
            .map(([key, value]) => ({
              orderId: key,
              orderDetails: value,
            }))
            .filter(order => order.orderDetails[0].order_id !== null); // Filtre les commandes sans order_id

          // Trie les commandes par order_id en ordre décroissant
          const sortedOrders = ordersArray.sort((a, b) => b.orderDetails[0].order_id - a.orderDetails[0].order_id);
          setOrders(sortedOrders);
        })
        .catch(error => console.error("Failed to fetch orders:", error));
    };

    fetchOrders(); // Récupérer les commandes au montage du composant
  }, []);

  return (
    <div>
      <MainNavigation />
      <div className="ordersListContainer">
        <h2>Orders</h2>
        {orders.map(({ orderId, orderDetails }) => (
          <OrdersCard key={orderId} orderDetails={orderDetails} />
        ))}
      </div>
    </div>
  );
};

export default ReceiptList;



