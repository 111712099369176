// src/components/rewardsCard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/rewardsCard.css';

const RewardsCard = ({ rewardName, message }) => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        console.log(`Navigating to QR code view for: ${rewardName}`);
        navigate(`/rewards-qr/${rewardName}`); // Assurez-vous que cette route est définie dans votre routeur
    };

    return (
        <div className="container" onClick={handleCardClick}>
          <div className="coupon-card">
              <h3>{rewardName}</h3>
              <p>{message}</p>
              <div className="circle1"></div>
              <div className="circle2"></div>
          </div>
        </div>
    );
};

export default RewardsCard;
