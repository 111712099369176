import React, { useEffect, useState } from 'react';
import images, { preloadImages } from './ImageLoader';
import '../styles/Animation.css';

const Animation = ({ onAnimationStart }) => {
  const [loaded, setLoaded] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isSliding, setIsSliding] = useState(true);

  useEffect(() => {
    preloadImages(images)
      .then(() => setLoaded(true))
      .catch((error) => console.error('Erreur de chargement des images', error));
  }, []);

  useEffect(() => {
    if (loaded) {
      if (isSliding) {
        const timeout = setTimeout(() => {
          setIsSliding(false);
          setCurrentImage(1); // Start the animation with Cargo_open_1.png after sliding
          if (onAnimationStart) {
            onAnimationStart();
          }
        }, 1000); // Duration of the slide-in animation (1s)

        return () => clearTimeout(timeout);
      } else {
        const interval = setInterval(() => {
          setCurrentImage((prev) => (prev < images.length - 1 ? prev + 1 : prev));
        }, 80); // Adjust the interval as needed

        return () => clearInterval(interval);
      }
    }
  }, [loaded, isSliding, onAnimationStart]);

  if (!loaded) {
    return <div></div>;
  }

  return (
    <div className="animation-container">
      <img
        src={process.env.PUBLIC_URL + images[currentImage]}
        alt="animation frame"
        className={isSliding ? 'first-image' : ''}
      />
    </div>
  );
};

export default Animation;


