import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../context/cartContext';
import '../styles/Cart.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const Cart = () => {
    const { cartItems, removeItem, incrementItem, decrementItem } = useContext(CartContext);
    const navigate = useNavigate();
    const calculateTotal = (items) => items.reduce((acc, item) => acc + item.price * item.quantity, 0);

    const handleCheckout = () => {
        const action = 'charge';
        const amount = calculateTotal(cartItems);
        navigate(`/manager/scan/${action}/${amount}`);
    };

    return (
      <div>
        <header className="cart-header">
            <h1 onClick={() => navigate('/')} style={{cursor: 'pointer'}}>CargoPero</h1>
        </header>
        <div className="cartContainer">
            <div className="cartHeader">CART</div>
            {cartItems.length === 0 && <div>The cart is empty.</div>}
            <div>
                {cartItems.map((item) => (
                    <div className="cartItem" key={item.id}>
                        <div className="cartItemImage">
                            <img src={item.img} alt={item.productName} />
                        </div>
                        <div className="cartItemDetails">
                            <h4>{item.productName}</h4>
                            <p>Prix: {item.price}</p>
                            <p>Quantité: {item.quantity}</p>
                            <div className="cartItemActions">
                                <button onClick={() => incrementItem(item.id)}>
                                    <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
                                </button>
                                <button onClick={() => decrementItem(item.id)}>
                                    <FontAwesomeIcon icon={faMinus} style={{ color: "#ffffff" }} />
                                </button>
                                <button onClick={() => removeItem(item.id)}>
                                    <FontAwesomeIcon icon={faTrash} style={{ color: "#ffffff" }} />
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="cartTotal">
                <h3>Total: {calculateTotal(cartItems)}</h3>
                <button className="checkout-btn" onClick={handleCheckout}>Checkout</button>
            </div>
        </div>
     </div>
  );
};

export default Cart;

