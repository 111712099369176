import React, { useState, useEffect } from 'react';
import '../styles/OrdersCard.css'; // Assurez-vous de créer ce fichier CSS basé sur ProductsCard.css

const OrdersCard = ({ orderDetails, onOrderUpdated }) => {
  console.log('orderDetails in OrdersCard:', orderDetails);
  const [userName, setUserName] = useState('');
  const [orderStatus, setOrderStatus] = useState(orderDetails[0].status); // Initialisation de l'état de orderStatus

  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL;
    fetch(`${API_URL}/api/users/hash/${orderDetails[0].userId}`)
      .then(response => response.json())
      .then(data => setUserName(data.name)); // Supposons que l'API retourne un objet avec une propriété 'name'
  }, [orderDetails]);

const handleProcessClick = () => {
    const orderId = parseInt(orderDetails[0].order_id, 10);
    console.log('Order ID is:', orderId);
    if (isNaN(orderId)) {
        console.error('Order ID is not un valid integer');
        return;
    }

    const userHash = localStorage.getItem('user_hash'); // Récupère le user_hash du localStorage
    const API_URL = process.env.REACT_APP_API_URL;
    const newStatus = orderStatus === 'processing' ? 'delivered' : 'processing';

    // Préparer le corps JSON
    const requestBody = {
        status: newStatus,
        staff_id: userHash
    };
    
    // Ajoutez un log ici pour vérifier les valeurs envoyées
    console.log('Sending Request Body:', requestBody);

    // Faire l'appel API pour mettre à jour la commande
    fetch(`${API_URL}/api/orders/update/${orderId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody) // Passer les données en JSON
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        return response.json();
    })
    .then(data => {
        console.log('Success:', data);
        setOrderStatus(newStatus); // Met à jour le statut de la commande dans le state

        if (newStatus === 'delivered') {
            onOrderUpdated(orderId);
        }
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

  return (
    <div className={`orderContainer ${orderStatus === 'processing' ? 'processingOrder' : ''}`}>
      <div className="orderCard">
        <div className="orderHeader">
          <h4>{userName}</h4>
          <h5>ID: {orderDetails[0].order_id}</h5>
        </div>
        <div className="orderBody">
          {orderDetails.map(item => (
            <div key={item.productName} className="orderItem">
              <span>{item.productName}</span>
              <span>{item.quantity}</span>
            </div>
          ))}
        </div>
        <div className="orderFooter">
          <span>{orderStatus}</span>
          <button type="button" className="processButton" onClick={handleProcessClick}>
            {orderStatus === 'processing' ? 'Validate' : 'Process'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrdersCard;

