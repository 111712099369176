import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../context/cartContext';
import '../styles/Cart.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const Checkout = () => {
    const { cartItems, removeItem, incrementItem, decrementItem } = useContext(CartContext);
    const navigate = useNavigate();
    const userHash = localStorage.getItem('user_hash');

    const calculateTotal = (items) => items.reduce((acc, item) => acc + item.price * item.quantity, 0);

    const submitSalesData = async (userHash, orderId) => {
    const payload = {
        userId: userHash, 
        products: cartItems.map(item => ({
            productName: item.productName, 
            quantity: item.quantity,
            orderId: orderId
        }))
    };

    try {
        const API_URL = process.env.REACT_APP_API_URL || 'http://your-api-url.com';
        console.log('Submitting sales data with orderId:', payload);
        const response = await fetch(`${API_URL}/api/sales`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            throw new Error('Failed to submit sales data');
        }

        const salesResponse = await response.json();
        console.log('Sales submission success:', salesResponse);
        return salesResponse; // S'assurer de retourner les données pour le logging
    } catch (error) {
        console.error('Sales submission error:', error);
    }
};

    const handleCheckout = async () => {
        const managerHash = "6f674cd9757cb29903d4d3d3b75f6c155a724c9cf793c7380d1c000589de1829";
        const amount = calculateTotal(cartItems);

        const payload = {
            userHash: userHash,
            managerHash: managerHash,
            amount: amount,
            type: 'Payment'
    };

    try {
        const API_URL = process.env.REACT_APP_API_URL || 'http://your-api-url.com';
        const response = await fetch(`${API_URL}/api/transactions/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        const apiResponse = await response.json();
        console.log('Transaction response:', apiResponse); // Log de la réponse de la transaction

        if (!response.ok) {
            if (apiResponse.error === 'Insufficient balance') {
                console.log('Redirect to error-balance-client would happen here');
                navigate('/error-balance-client');
            } else {
                throw new Error(apiResponse.error || 'Failed to process payment');
            }
        } else {
            if (apiResponse && apiResponse.transactionId) {
                // Log que nous sommes sur le point de soumettre les données de vente
                console.log('About to submit sales data...');
                const salesResponse = await submitSalesData(userHash, apiResponse.transactionId); // Maintenant, nous attendons et loguons la réponse de submitSalesData
                console.log('Sales data submitted:', salesResponse);

                // Log pour la redirection qui serait exécutée
                console.log(`Redirect to /success-payment/${apiResponse.amount}/${apiResponse.transactionId}/${apiResponse.userHash} would happen here`);
                navigate(`/success-payment/${apiResponse.amount}/${apiResponse.transactionId}/${apiResponse.userHash}`, { state: { cartItems: cartItems } });
            } else {
                console.error('Transaction successful but missing data');
            }
        }
    } catch (error) {
        console.error('Payment processing error:', error);
    }
};

    return (
        <div>
            <header className="cart-header">
                <h1 onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>CargoPero</h1>
            </header>
            <div className="cartContainer">
                <div className="cartHeader">CART</div>
                {cartItems.length === 0 && <div>The cart is empty.</div>}
                <div>
                    {cartItems.map((item) => (
                        <div className="cartItem" key={item.id}>
                            <div className="cartItemImage">
                                <img src={item.img} alt={item.productName} />
                            </div>
                            <div className="cartItemDetails">
                                <h4>{item.productName}</h4>
                                <p>Price: {item.price}</p>
                                <p>Quantity: {item.quantity}</p>
                                <div className="cartItemActions">
                                    <button onClick={() => incrementItem(item.id)}>
                                        <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
                                    </button>
                                    <button onClick={() => decrementItem(item.id)}>
                                        <FontAwesomeIcon icon={faMinus} style={{ color: "#ffffff" }} />
                                    </button>
                                    <button onClick={() => removeItem(item.id)}>
                                        <FontAwesomeIcon icon={faTrash} style={{ color: "#ffffff" }} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="cartTotal">
                    <h3>Total: {calculateTotal(cartItems)}</h3>
                    <button className="checkout-btn" onClick={handleCheckout}>Checkout</button>
                </div>
            </div>
        </div>
    );
};

export default Checkout;

