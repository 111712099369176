import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import OrdersCard from './OrdersCard';
import MainNavigation from './MainNavigationStaff'
import '../styles/OrdersList.css';

const OrdersList = () => {
    
  const handleOrderUpdated = (orderId) => {
  setOrders(prevOrders => {
    const updatedOrders = { ...prevOrders };
    delete updatedOrders[orderId];
    return updatedOrders;
  });
};
  const [orders, setOrders] = useState({});

  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL;
    console.log('API_URL:', API_URL); // Log l'URL de l'API

    fetch(`${API_URL}/api/orders`)
      .then(response => response.json())
      .then(data => {
        console.log('Initial orders fetched:', data); // Log des commandes initialement récupérées
        setOrders(data);
      });

    const socket = io(API_URL);
    console.log('Socket initialized:', socket); // Log de l'initialisation du socket

    socket.on('newSale', ({ order_id }) => {
      console.log('Received newSale event with order_id:', order_id); // Log à la réception d'un newSale

      fetch(`${API_URL}/api/orders/${order_id}`)
        .then(response => response.json())
        .then(orderDetails => {
          console.log('Order details fetched for newSale:', orderDetails); // Log des détails de la commande récupérés
          setOrders(prevOrders => {
            const updatedOrders = {
              ...prevOrders,
              [order_id]: orderDetails
            };
            console.log('Orders state after newSale update:', updatedOrders); // Log de l'état des commandes après mise à jour
            return updatedOrders;
          });
        })
        .catch(error => console.error("Failed to fetch order details:", error));
    });

    socket.on('orderUpdated', ({ staff_id, orderId }) => {
        console.log('Received orderUpdated event with staff_id and orderId:', staff_id, orderId); // Log à la réception d'un orderUpdated
        const userHashLocal = localStorage.getItem('user_hash');
        console.log('Local user_hash:', userHashLocal); // Log du user_hash local

        if (userHashLocal && userHashLocal !== staff_id) {
            setOrders(prevOrders => {
                const updatedOrders = { ...prevOrders };
                if (updatedOrders[orderId]) {
                    console.log('Deleting order for non-matching user_hash:', orderId); // Log de la suppression de la commande
                    delete updatedOrders[orderId];
                }
                console.log('Orders state after orderUpdated deletion:', updatedOrders); // Log de l'état des commandes après suppression
                return updatedOrders;
            });
        } else {
            console.log('No action taken for orderUpdated, user_hash matches or no local user_hash.'); // Log si aucune action n'est prise
        }
    });

    return () => {
      socket.off('newSale');
      socket.off('orderUpdated');
      socket.disconnect();
      console.log('Socket disconnected'); // Log de la déconnexion du socket
    };
  }, []);

  return (
   <div>
    <MainNavigation />
    <div className="ordersListContainer">
      <h2>Orders</h2>
      {Object.entries(orders).map(([orderId, orderDetails]) => (
        <OrdersCard key={orderId} orderDetails={orderDetails} onOrderUpdated={handleOrderUpdated} />
      ))}
    </div>
   </div>
  );
};

export default OrdersList;


