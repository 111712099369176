import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import { auth } from '../firebaseConfig';
import { getAuth, signOut } from 'firebase/auth';
import MainNavigation from './MainNavigation';
import '../styles/QRcode.css';

const QRCodeView = ({ setShowQRCode }) => {
    const navigate = useNavigate();
    
    // Initialiser userHash avec la valeur stockée dans localStorage ou utiliser une valeur par défaut si non disponible
    const [userHash, setUserHash] = useState(localStorage.getItem('user_hash') || 'default_hash');
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        // Récupération du solde du portefeuille
        const fetchWalletBalance = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL
                const response = await fetch(`${apiUrl}/api/wallets/balance/${userHash}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch wallet balance');
                }
                const data = await response.json();
                setBalance(data.balance);
            } catch (error) {
                console.error('Error fetching wallet balance:', error);
            }
        };

        fetchWalletBalance();
    }, [userHash]);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_API_URL);

        socket.on('transactionUpdate', (data) => {
            console.log('Received transactionUpdate:', data);

            if (data.userHash === userHash) {
                console.log('UserHash matches. Checking type...');
                if (data.type.toLowerCase() === 'topup') {
                    console.log('Navigating to success-topup');
                    navigate(`/success-topup/${data.amount}/${data.userHash}`);
                } else if (data.type.toLowerCase() === 'payment') {
                    console.log('Navigating to success-payment');
                    navigate(`/success-payment/${data.amount}/${data.userHash}`);
                }
            }
        });

        return () => {
            socket.off('transactionUpdate');
            socket.close();
        };
    }, [userHash, navigate]);
    
     const handleLogout = async () => {
        try {
            await signOut(auth); // Déconnecter l'utilisateur via Firebase
            localStorage.clear()
            console.log("User Signed Out Successfully!");
            navigate('/login'); // Rediriger l'utilisateur vers la page de connexion
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
      <div>
        <MainNavigation />   
        <div className="qrCodeView">
            <h2>WALLET</h2>
            <p>Balance</p>
            <span>MMK {balance}.00</span>
            <div className="qrCodeContainer">
              <QRCode value={userHash} size={250}/>
            </div>
            <b>{userHash}</b>
        </div>
      </div>
    );
};

export default QRCodeView;
