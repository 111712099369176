const images = [];

for (let i = 0; i <= 52; i++) {
  images.push(`/animations/Cargo_open_${i}.png`);
}

export const preloadImages = (imageArray) => {
  const promises = imageArray.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = process.env.PUBLIC_URL + src;
      img.onload = resolve;
      img.onerror = reject;
    });
  });
  return Promise.all(promises);
};

export default images;
