import React, { useState, useRef, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';
import io from 'socket.io-client';
import '../styles/ManagerScan.css';
import { CartContext } from '../context/cartContext'; // Importer CartContext

const ManagerScan = () => {
    const [scanResult, setScanResult] = useState(null);
    const [isScanning, setIsScanning] = useState(true);
    const { action, amount } = useParams();
    const navigate = useNavigate();
    const hasScannedRef = useRef(false);
    const qrReaderRef = useRef(null);
    const socket = io(process.env.REACT_APP_SOCKET_URL); // Remplacez par l'URL de votre serveur WebSocket
    const { cartItems } = useContext(CartContext);

    useEffect(() => {
        return () => {
            if (qrReaderRef.current && qrReaderRef.current.state) {
                const tracks = qrReaderRef.current.state.qrReader.videoElement.srcObject?.getTracks();
                tracks?.forEach(track => track.stop());
            }
        };
    }, []);

    const handleScan = async (data) => {
        if (data && isScanning && !hasScannedRef.current) {
            hasScannedRef.current = true;
            setIsScanning(false);
            setScanResult(data.text);
            const apiResponse = await createTransaction(data.text);

            if (apiResponse && apiResponse.transactionId) {
                socket.emit('transactionSuccess', {
                    transactionId: apiResponse.transactionId,
                    amount: apiResponse.amount,
                    userHash: apiResponse.userHash,
                    type: apiResponse.type,
                });
                console.log('Emitted transactionSuccess:', {
                    transactionId: apiResponse.transactionId,
                    amount: apiResponse.amount,
                    userHash: apiResponse.userHash
                });
                if (apiResponse.type === 'TopUp') {
                    navigate(`/man-topup/${apiResponse.amount}/${apiResponse.userHash}`);
                } else if (apiResponse.type === 'Payment') {
                    // Appel à submitSalesData uniquement pour les paiements
                    await submitSalesData(apiResponse.userHash);
                    navigate(`/man-payment/${apiResponse.amount}/${apiResponse.userHash}`);
                }
            } else if (apiResponse && apiResponse.error === 'Insufficient balance') {
                navigate(`/error-balance/`);
            }
        }
    };

    const createTransaction = async (scannedData) => {
    console.log(`Data scanned: ${scannedData}`); // Log pour voir les données scannées

    const managerHash = "6f674cd9757cb29903d4d3d3b75f6c155a724c9cf793c7380d1c000589de1829";
    let userHash = scannedData, rewardName;

    // Initialiser le montant par défaut à celui spécifié dans les paramètres
    let transactionAmount = parseFloat(amount);
        console.log(`Initial amount is: ${amount}`)

    // Contrôle pour un reward uniquement si l'action est 'charge'
    if (action === 'charge' && scannedData.includes('_')) {
        [userHash, rewardName] = scannedData.split('_');
        console.log(`User Hash: ${userHash}, Reward Name: ${rewardName}`); // Log pour vérifier la séparation
        
        // Si un reward est détecté et que l'action est 'charge', fixer le montant à 0
        transactionAmount = 0;
    } else {
        console.log(`User Hash: ${userHash}`); // Log pour userHash sans reward ou pour les actions autres que 'charge'
    }

    const payload = {
        userHash: userHash,
        managerHash: managerHash,
        amount: transactionAmount, // Utiliser la variable transactionAmount
        type: action === 'topup' ? 'TopUp' : 'Payment',
        ...(rewardName && {rewardName}) // Ajout du rewardName au payload si présent et action est 'charge'
    };

    console.log(`Payload for transaction:`, payload); // Log pour voir le payload final avant l'envoi

    try {
        const API_URL = process.env.REACT_APP_API_URL || 'https://cashqr.local:3002';
        const response = await fetch(`${API_URL}/api/transactions/create`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('API Error Data:', errorData); // Log pour les erreurs de l'API
            return errorData;
        }

        const data = await response.json();
        console.log('Success:', data); // Log pour une réponse réussie
        return data;
    } catch (error) {
        console.error('Error during transaction creation:', error); // Log pour les erreurs de catch
        setIsScanning(true);
        return null;
    }
};

    
    const submitSalesData = async (userHash) => {
        const payload = {
            userId: userHash, // ou une autre manière d'obtenir l'ID de l'utilisateur si nécessaire
            products: cartItems.map(item => ({
                productName: item.productName, // Assurez-vous que les noms des propriétés correspondent à ceux attendus par votre backend
                quantity: item.quantity
            }))
        };

        try {
            const API_URL = process.env.REACT_APP_API_URL || 'https://cashqr.local:3002';
            const response = await fetch(`${API_URL}/api/sales`, { // utilisez le bon endpoint
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            console.log('Raw Response:', response);  
            if (!response.ok) {
                const errorData = await response.json();
                console.error('API Error Data:', errorData);
                return errorData;
            }
            const data = await response.json();
            console.log('Sales submission success:', data);
            return data;
        } catch (error) {
            console.error('Error:', error);
            setIsScanning(true);
            return null;
        }
    };
    
    const getActionproductName = (action) => {
        switch (action) {
            case 'topup':
                return 'Top Up';
            case 'charge':
                return 'Charge';
            default:
                return ''; // ou une valeur par défaut si nécessaire
        }
    };

    const actionproductName = getActionproductName(action);
    
    return (
        <div>
          <header className="scan-header">
            <h1 onClick={() => navigate('/')} style={{cursor: 'pointer'}}>CargoPero</h1>
          </header>
            <div className="scanView">
                <h1>{actionproductName} QR code</h1>
                <div style={{ width: '100%', maxWidth: '500px', height: 'auto' }}> {/* Ajustez la largeur et la hauteur selon vos besoins */}
                  <QrReader
                    ref={qrReaderRef}
                    delay={isScanning ? 300 : false}
                    onResult={handleScan}
                    onError={(err) => console.error(err)}
                    constraints={{ facingMode: 'environment' }}
                    style={{ width: '100%' }}
                  />
                </div>
                {scanResult && (
                    <div className="scanResult">
                        <p>{scanResult}</p>
                        <button onClick={() => {
                            hasScannedRef.current = false;
                            setScanResult(null);
                            setIsScanning(true);
                        }}>Scan</button>
                    </div>
                )}
            </div>
          </div>
    );
};

export default ManagerScan;
