import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../styles/VerifyEmail.css';

// Import the functions needed from the Firebase modules
import { getAuth, sendEmailVerification } from 'firebase/auth';

const VerifyEmail = () => {
    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState(''); // Ajout d'un state pour stocker l'email
    const auth = getAuth();

    useEffect(() => {
        // Use the modular function getAuth to get the auth instance
        const user = auth.currentUser;

        if (user && !user.emailVerified) {
            // Use the sendEmailVerification function from the modular API
            sendEmailVerification(user).then(() => {
                console.log('Email verification sent!');
            });
        } else if (user && user.emailVerified) {
            // Si l'email a été vérifié entre-temps, rediriger l'utilisateur
            navigate('/login'); // Rediriger vers la page d'accueil ou la page de profil
        }
    }, [navigate]);

    return (
        <div>
          <header className="verifEmailHeader">
          <h1 onClick={() => navigate('/')} style={{cursor: 'pointer'}}>CargoPero</h1>
          </header>
          <div className="verifEmail">
            <FontAwesomeIcon icon={faEnvelope} size="6x" style={{ color: "#2563eb", marginTop: "30px" }} />
            <span>Check Your Email</span>
            <h1>We've just sent a link to your email. Verify your address and we'll get you all set up!</h1>
          </div>
        </div>
    );
};

export default VerifyEmail;

