import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PacmanLoader } from 'react-spinners';
import '../styles/Action.css';

const ActionPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Extrait les paramètres de l'URL
        const queryParams = new URLSearchParams(location.search);
        const mode = queryParams.get('mode');
        const oobCode = queryParams.get('oobCode');
        const apiKey = queryParams.get('apiKey');

        // Effectue une requête au serveur
        const performAction = async () => {
            try {
                const response = await fetch(`https://cargopero.com/api/actions/redirect?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}`);
                if (response.ok) {
                    // Supposons que le serveur renvoie une URL de redirection dans le corps de la réponse
                    const data = await response.json();
                    const redirectUrl = data.redirectUrl;
                    navigate(redirectUrl);
                } else {
                    console.error('Erreur lors de la requête au serveur.');
                }
            } catch (error) {
                console.error('Erreur lors de la communication avec le serveur:', error);
            }
        };

        performAction();
    }, [location.search, navigate]);

    return (
        <div className="spinner-container">
          <p>Processing your request ...</p> 
        <PacmanLoader color="#2563eb" />
    </div>
    );
};

export default ActionPage;
