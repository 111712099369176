import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '../styles/LandingPage.css';
import Animation from './Animation';

function LandingPage() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Si l'utilisateur est connecté, redirigez-le vers '/qrcode'
        navigate('/qrcode');
      }
      // Si l'utilisateur n'est pas connecté, rien ne se passe, il reste sur cette page
    });

    // Se désabonner de l'écouteur à la désinscription pour éviter les fuites de mémoire
    return () => unsubscribe();
  }, [navigate, auth]);

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  return (
    <div className="landing-container">
      <header className="landing-header">
        <h1 onClick={() => navigate('/')} style={{cursor: 'pointer'}}></h1>
      </header>
      <Animation onAnimationStart={() => setAnimationStarted(true)} />
      <main className="landing-main">
        <h2>Sip On The Go!</h2>
        <button className="landing-button" onClick={handleLoginClick} disabled={!animationStarted}>
          Log in
        </button>
        <button className="landing-button" onClick={handleSignUpClick} disabled={!animationStarted}>
          Sign up
        </button>
      </main>
      <footer className="landing-footer">
        <p>by Lolevray</p>
        <div>
          <button className="link-button" onClick={() => navigate('/terms')} disabled={!animationStarted}>
            Terms of use
          </button>
          <span> | </span>
          <button className="link-button" onClick={() => navigate('/privacy')} disabled={!animationStarted}>
            Privacy policy
          </button>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;

