// src/components/ProductsList.js
import React, { useContext, useState, useEffect } from 'react'; // Ajouter useState et useEffect
import { useNavigate } from 'react-router-dom';
import ProductsCard from './ProductsCard';
// Supprimer l'importation des données produits locales
import { CartContext } from '../context/cartContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import '../styles/ProductsList.css';

const ProductsList = () => {
    const navigate = useNavigate();
    const { cartItems, clearCart } = useContext(CartContext);
    const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
    const apiUrl = process.env.REACT_APP_API_URL

    
    const [productsData, setProductsData] = useState([]); // Ajouter un état pour stocker les données des produits
    useEffect(() => {
      clearCart(); // Ceci réinitialisera le panier chaque fois que le composant est monté
      }, []);
    
    useEffect(() => {
        // Ajouter le chargement des données de produits depuis le serveur
        fetch(`${apiUrl}/api/products`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('La requête a échoué.');
            })
            .then(data => setProductsData(data))
            .catch(error => console.error('Erreur lors de la récupération des produits:', error));
    }, []); // Le tableau vide assure que l'effet s'exécute une seule fois après le premier rendu

    return (
      <div>
        <header className="list-header">
            <h1 onClick={() => navigate('/')} style={{cursor: 'pointer'}}>CargoPero</h1>
            <button className="cart-button" onClick={() => navigate('/cart')}>
                <FontAwesomeIcon icon={faCartShopping} style={{ color: "#ffffff", fontSize: '3em' }} />
                {cartItems.length > 0 && (
                    <span className="cart-item-count">{totalItems}</span>
                )}
            </button>
        </header>
        <div className="listContainer">
            <h2>DRINKS</h2>
            {productsData.map(product => (
                <ProductsCard key={product.id} {...product} />
            ))}
        </div>
      </div>
    );
};

export default ProductsList;
