// src/context/cartContext.js

import React, { createContext, useReducer } from 'react';
import cartReducer from './cartReducer'; // Assurez-vous que le chemin est correct

/* Cart Context */
const CartContext = createContext();

/* Initial State */
const initialState = {
    isCartOpen: false,
    cartItems: []
};

/* Cart-Provider Component */
const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState);

    /* Dispatched Actions */
    const toggleCart = (toggle) => {
        dispatch({
            type: 'TOGGLE_CART',
            payload: { toggle }
        });
    };

    const addItem = (item) => {
        console.log('addItem called with:', item);
        dispatch({
            type: 'ADD_TO_CART',
            payload: { item }
        });
    };

    const removeItem = (itemId) => {
        dispatch({
            type: 'REMOVE_FROM_CART',
            payload: { itemId }
        });
    };

    const incrementItem = (itemId) => {
        dispatch({
            type: 'INCREMENT',
            payload: { itemId }
        });
    };

    const decrementItem = (itemId) => {
        dispatch({
            type: 'DECREMENT',
            payload: { itemId }
        });
    };

    const clearCart = () => {
        dispatch({ type: 'CLEAR_CART' });
    };

    // Context values
    const values = {
        ...state,
        toggleCart,
        addItem,
        removeItem,
        incrementItem,
        decrementItem,
        clearCart
    };

    return (
        <CartContext.Provider value={values}>
            {children}
        </CartContext.Provider>
    );
};

export { CartContext, CartProvider };
