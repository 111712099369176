// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDv3dGPBav8B4rzPeAZ315nC7Cqwjh1AA4",
  authDomain: "cyclopero.firebaseapp.com",
  projectId: "cyclopero",
  storageBucket: "cyclopero.appspot.com",
  messagingSenderId: "433597056887",
  appId: "1:433597056887:web:78d69e4564be1b0f08c372"
};

// Initialisation de Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth }; // Exportation de l'instance auth pour l'utiliser dans d'autres fichiers
