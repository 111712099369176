import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import '../styles/ManSuccessTopUp.css';

const ScanSuccessTopUp = () => {
  const { amount, userHash } = useParams();
  const navigate = useNavigate();
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    const fetchWalletBalance = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL || 'https://cashqr.local:3002';
        const url = `${apiUrl}/api/wallets/balance/${userHash}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
            // Ajoutez d'autres headers ici si nécessaire
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP status ${response.status}`);
        }

        const data = await response.json();
        setBalance(data.balance);
      } catch (error) {
        console.error('Failed to fetch wallet balance:', error);
      }
    };

    fetchWalletBalance();
  }, [userHash]);

  const handleBackToScan = () => {
    navigate('/manager/');
  };

  return (
      <div>
        <header className="successTopUpHeader">
        <h1 onClick={() => navigate('/')} style={{cursor: 'pointer'}}>CargoPero</h1>
        </header>
        <div className="successTopUp">
          <FontAwesomeIcon icon={faCircleCheck} size="6x" style={{ color: "#2563eb", marginTop: "30px" }} />
          <h1>TRANSACTION SUCCESSFUL!</h1>
          <p>Account has been topped up with {amount} MMK</p>
          <p>New balance</p>
          <span>MMK {balance}.00 </span>
          <button onClick={handleBackToScan}>Back to scan</button>
        </div>
      </div>
  );
};

export default ScanSuccessTopUp;