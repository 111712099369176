import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithCustomToken, sendEmailVerification, signOut } from 'firebase/auth';
import { PacmanLoader } from 'react-spinners';
import '../styles/Signup.css';

const Signup = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '', // Optional field
    role: 'client', // Default role
    referralID: '', // Add referralID field
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(''); // Ajout de l'état d'erreur
    
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const referralID = searchParams.get('referralID'); // Adjust the query param as per your URL structure
    if (referralID) {
      setUserData((prevData) => ({ ...prevData, referralID }));
    }
  }, []);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(''); // Réinitialiser l'état d'erreur
    try {
      const apiUrl = process.env.REACT_APP_API_URL    
      const response = await fetch(`${apiUrl}/api/users/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });
        
      const result = await response.json(); // Lire le corps de la réponse

      if (!response.ok) {
        // Si la réponse n'est pas ok, utilisez le message d'erreur du corps de la réponse
        throw new Error(result.error || `HTTP error! status: ${response.status}`);
      }

      console.log('Response from server:', result); // Logger la réponse du serveur

      // Utiliser le customToken pour authentifier temporairement l'utilisateur
      await signInWithCustomToken(auth, result.customToken);

      // Envoyer l'email de vérification
      if (auth.currentUser) {
        await sendEmailVerification(auth.currentUser);
        console.log('Verification email sent.'); // Logger le succès de l'envoi de l'email
      }
      // Déconnecter l'utilisateur
      await signOut(auth);
      console.log('User signed out.'); // Logger la déconnexion de l'utilisateur

      // Naviguer vers la page de vérification d'email
      navigate('/verify-email');
    } catch (error) {
      console.error('Error during signup:', error);
      setError(error.message); // Mettre à jour l'état de l'erreur avec le message approprié
    } finally {
      setIsLoading(false);
    }   
  };

  return (
    <div>
      {isLoading ? (
        <div className="spinner-container">
            <PacmanLoader color="#2563eb" />
        </div>
      ) : (
        <div>
          <header className="signup-header">
            <h1 onClick={() => navigate('/')} style={{cursor: 'pointer'}}>CargoPero</h1>
          </header>
          <div className="signup-container">
            {error && <p className="error-message">{error}</p>} {/* Afficher le message d'erreur ici */}
            <form className="signup-form" onSubmit={e => e.preventDefault()}>
              <input type="text" name="name" placeholder="Name" value={userData.name} onChange={handleChange} required />
              <input type="email" name="email" placeholder="Email" value={userData.email} onChange={handleChange} required />
              <input type="tel" name="phone" placeholder="Phone (Optional)" value={userData.phone} onChange={handleChange} />
              <input type="password" name="password" placeholder="Password" value={userData.password} onChange={handleChange} required />
              <button onClick={handleSubmit} type="submit">Sign Up</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;



