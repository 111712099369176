import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import '../styles/ErrorBalance.css';

const ErrorBalanceClient = () => {
  const navigate = useNavigate();

  const handleBackToScan = () => {
    navigate('/qrcode/');
  };

  return (
    <div>
      <header className="lowBalanceHeader">
      <h1 onClick={() => navigate('/')} style={{cursor: 'pointer'}}>CargoPero</h1>
      </header>
        <div className="lowBalance">
          <FontAwesomeIcon icon={faCircleExclamation} size="6x" style={{ color: "#2563eb", marginTop: "30px" }} />
          <h1>Balance too low :(</h1>
          <p>Top Up first!</p>
          <button onClick={handleBackToScan}>Back to Wallet</button>
        </div>
    </div>
  );
};

export default ErrorBalanceClient;
