import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import '../styles/SuccessPayment.css';

const ScanSuccessPayment = () => {
  const { amount, transactionId, userHash } = useParams();
  const navigate = useNavigate();
  const location = useLocation(); // Utiliser useLocation pour accéder à l'état de navigation
  const [balance, setBalance] = useState(null);

  // Extraire cartItems de l'état de navigation, avec un tableau vide comme valeur par défaut
  const { cartItems } = location.state || { cartItems: [] };

  // Ajout de logs pour déboguer
  console.log('Location state:', location.state);
  console.log('Cart Items:', cartItems);

  useEffect(() => {
    const fetchWalletBalance = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL || 'https://cashqr.local:3002';
        const url = `${apiUrl}/api/wallets/balance/${userHash}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
            // Ajoutez d'autres headers ici si nécessaire
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP status ${response.status}`);
        }

        const data = await response.json();
        setBalance(data.balance);
        console.log('Fetched Wallet Balance:', data.balance); // Log pour le solde du portefeuille
      } catch (error) {
        console.error('Failed to fetch wallet balance:', error);
      }
    };

    fetchWalletBalance();
  }, [userHash]);

  const handleBackToScan = () => {
    navigate('/receipts/');
  };

  return (
      <div>
        <header className="success-header">
        <h1 onClick={() => navigate('/')} style={{cursor: 'pointer'}}>CargoPero</h1>
        </header>
        <div className="clientSuccessPayment">
          <FontAwesomeIcon icon={faCircleCheck} size="6x" style={{ color: "#2563eb", marginTop: "30px" }} />
          <h1>TRANSACTION SUCCESSFUL!</h1>
          <p>You have been charged <strong style={{ fontWeight: 'bold', fontSize: '1.2em' }}>MMK {amount}.00</strong> for order ID: <strong style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{transactionId}</strong></p>
           <div className="info-box">
             <p style={{color:"#343541"}}>Your order has been sent to the bar. Please wait a moment while we prepare your drink 🍹</p>
           </div>
          <p>New balance</p> 
          <span>MMK {balance}.00</span>
          <button onClick={handleBackToScan}>Check orders</button>
        </div>
      </div>
  );
};

export default ScanSuccessPayment;

