import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, applyActionCode } from 'firebase/auth';
import { PacmanLoader } from 'react-spinners';
import '../styles/validateEmail.css';

const EmailVerificationRedirect = () => {
  const navigate = useNavigate();
  const auth = getAuth(); // Obtenez l'instance auth

  useEffect(() => {
    const handleEmailVerification = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const actionCode = urlParams.get('oobCode'); // Récupération directe du code d'action

      if (actionCode) {
        try {
          // Appliquez le code d'action pour vérifier l'email
          await applyActionCode(auth, actionCode);
          console.log('Email verified successfully'); // Log pour le succès
          navigate('/login'); // Redirection après la vérification
        } catch (error) {
          console.error('Email verification failed:', error); // Gestion des erreurs
        }
      } else {
        console.log('No action code provided.'); // Log si le code d'action est manquant
      }
    };
    
    handleEmailVerification();
  }, [navigate, auth]);

  return (
    <div className="spinner-container">
      <p>Checking your email</p> 
      <PacmanLoader color="#2563eb" />
    </div>
  );
};

export default EmailVerificationRedirect;

