// src/context/cartReducer.js

const cartReducer = (state, action) => {
    console.log('Action:', action); // Affiche l'action en cours
    switch (action.type) {
        case 'TOGGLE_CART':
            return {
                ...state,
                isCartOpen: action.payload.toggle
            };

        case 'ADD_TO_CART':
            const newItem = action.payload.item;
            const itemExists = state.cartItems.find(item => item.id === newItem.id);

            if (itemExists) {
                console.log('Existing item, incrementing quantity', { item: newItem, cartItems: state.cartItems });
                return {
                    ...state,
                    cartItems: state.cartItems.map(item =>
                        item.id === newItem.id ? { ...item, quantity: item.quantity + 1 } : item
                    )
                };
            } else {
                console.log('Adding new item to cart', { item: newItem, cartItems: state.cartItems });
                return {
                    ...state,
                    cartItems: [...state.cartItems, { ...newItem, quantity: 1 }]
                };
            }

        case 'REMOVE_FROM_CART':
            return {
                ...state,
                cartItems: state.cartItems.filter(item => item.id !== action.payload.itemId)
            };

        case 'INCREMENT':
            return {
                ...state,
                cartItems: state.cartItems.map(item =>
                    item.id === action.payload.itemId ? { ...item, quantity: item.quantity + 1 } : item
                )
            };

        case 'DECREMENT':
            return {
                ...state,
                cartItems: state.cartItems.map(item =>
                    item.id === action.payload.itemId ? { ...item, quantity: Math.max(0, item.quantity - 1) } : item
                )
            };

        case 'CLEAR_CART':
            return {
                ...state,
                cartItems: []
            };

        default:
            return state;
    }
};

export default cartReducer;
