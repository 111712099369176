import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ManagerChoice.css';
import MainNavigationManager from './MainNavigationManager';

// Simple Modal component to input amount
const AmountModal = ({ action, onSubmit, onClose }) => {
  const [amount, setAmount] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(action, amount);
  };

  return (
    <div className="modal-container">
      <h2>{action} Amount</h2>
      <form onSubmit={handleSubmit}>
        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} required />
        <button type="submit">Submit</button>
        <button onClick={onClose}>Close</button>
      </form>
    </div>
  );
};

const ManagerChoice = () => {
  const navigate = useNavigate();
  const [modalAction, setModalAction] = useState(null);

  const navigateToScan = (action, amount) => {
    navigate(`/manager/scan/${action}/${amount}`);
  };

  const handleActionClick = (action) => {
    setModalAction(action);
  };
    
  const handleNavigateToProducts = () => {
        navigate('/products'); 
    };

  const closeModal = () => {
    setModalAction(null);
  };

  return (
    <div>
      <MainNavigationManager />
      <div className="choice-container">
        <h2>Select transaction type</h2>
        <button onClick={() => handleActionClick('topup')}>Top Up</button>
        <button onClick={handleNavigateToProducts}>Charge</button>
        {modalAction && <AmountModal action={modalAction} onSubmit={navigateToScan} onClose={closeModal} />}
    </div>
   </div>
  );
};

export default ManagerChoice;
