// src/components/ResetPassword.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Importez votre configuration Firebase
import { sendPasswordResetEmail } from 'firebase/auth';
import '../styles/PasswordResetRequest.css'; // Utilisez le même style que Login

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    if (!email) {
      setError('Please enter your email address.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset link sent! Check your email.');
      navigate('/login'); // Rediriger vers la page de connexion après l'envoi
    } catch (error) {
      console.error(error);
      setError('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <div className="main-container">
      <header className="reset-header">
        <h1 onClick={() => navigate('/')} style={{cursor: 'pointer'}}>CargoPero</h1>
      </header>
      <div className="reset-container">
       {error && <p className="error-message">{error}</p>}
       <input 
        type="email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        className="reset-input" 
        placeholder="Enter your email"
       />
       <button onClick={handleResetPassword} className="reset-button">
        Reset Password
       </button>
      </div>
      <a onClick={() => navigate('/login')} className="reset-link">Back to Login</a>
    </div>
  );
};

export default ResetPassword;
